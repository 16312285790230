<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>品类管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="品类名称">
                                <el-input v-model="search.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="创建日期">
                                <el-date-picker style="width: 100%" v-model="searchtime" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd">
                                </el-date-picker>

                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="品类类型">
                                <el-select v-model="search.cate_flag" placeholder="请选择类型" style="width: 100%">
                                    <el-option label="全部" :value=1></el-option>
                                    <el-option label="一级运动品类" :value=2></el-option>
                                    <el-option label="二级运动品类" :value=3></el-option>
                                    <el-option label="运动效果" :value=4></el-option>
                                    <el-option label="心理需求" :value=5></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('categorytags.tags.savedata')" icon="el-icon-plus" size="medium"
                            type="primary" @click="tosee()">添加品类
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID" width="60" />
                <el-table-column prop="name" label="品类名称" />
                <el-table-column prop="type_name" label="品类类型" />
                <el-table-column prop="logo" label="图片" width="100">
                    <template slot-scope="scope">
                        <section v-if="scope.row.logo" style="display:flex;justify-content: center;">
                            <el-avatar shape="square" :size="62" :src="scope.row.logo">
                            </el-avatar>
                        </section>
                        <p style="margin:0;text-align: center;" v-else>/</p>
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序" width="80">
                    <template slot-scope="scope">
                        <p style="margin:0;text-align: center;">{{ scope.row.sort }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间">
                </el-table-column>
                <el-table-column prop="state" label="状态">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state === 1" type="success">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state === 2" type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="240">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('categorytags.tags.getinfo')" size="mini"
                            @click="tosee(scope.row.id, 'see')">查看
                        </el-button>
                        <el-button v-if="is_auth('categorytags.tags.savedata')" type="primary"
                            @click="tosee(scope.row.id, 'edit')" size="mini">编辑
                        </el-button>
                        <!-- <el-button v-if="is_auth('weapphome.like.isenable') && scope.row.state===2 "
                            @click="operation_tip(scope.row.id,scope.row.title,'isenable')" type="primary" size="mini">
                            启用
                        </el-button> -->
                        <!-- <el-button v-if="is_auth('weapphome.like.isdisable') && scope.row.state===1 "
                            @click="operation_tip(scope.row.id,scope.row.title,'isdisable')" type="danger" size="mini">
                            禁用
                        </el-button> -->
                        <el-button v-if="is_auth('categorytags.tags.changestate')"
                            @click="operation_tip(scope.row.id, scope.row.name, 'isdel')" type="danger" size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '品类列表',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
            searchtime: {}
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                tag_s: '',
            }
            this.search = {
                name: "",
                state: "",
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "categorytags.tags.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0];
                postdata.endtime = this.searchtime[1];
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(id = '', title = '') {
            // 弹出二次确认
            this.$confirm('删除【' + title + '】？', '确认信息', { type: 'error' })
                .then(() => {
                    this.isoperation(id)
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(id = '') {
            let postdata = {
                api_name: "categorytags.tags.changestate",
                token: this.Tool.get_l_cache('token'),
                id: id,
                state: -1
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(id = '', type) {
            if (id === '') {
                this.$router.push({ path: '/weapphome/category/add' })
            } else {
                this.$router.push({ path: '/weapphome/category/add', query: { id: id, type: type } })
            }
        },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}
</style>
